import { Link } from "react-router-dom"; // Import Link component
import NavLinks from "./NavLinks";

const Header = () => {
	return (
		<header className="header">
			<Link to="/" className="logo-link">
				<h1 className="logo">
					<span className="logo_first_letter">H</span>
					<span className="logo_second_letter">G</span>
				</h1>
			</Link>
			<NavLinks />
		</header>
	);
};

export default Header;
