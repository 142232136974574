import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";
import ParticlesBg from "./components/ParticlesBg";
import { useEffect, useState } from "react";
import ThemeToggler from "./components/ThemeToggler";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
	const personalDetails = {
		name: "Hrithvik Gadhiya",
		location: "Calgary, AB, Canada",
		email: "gadhiyahrithvik@gmail.com",
		availability: "Open to new opportunities",
		brand:
			"I am an enthusiastic Full Stack Engineer, adept at delivering top-notch digital solutions. With a solid foundation in both front and back-end technologies, coupled with experience in infrastructure and networking, I confidently navigate the development landscape to create scalable, secure, and high-performance applications. I don't just solve problems—I anticipate them, embracing every challenge as a learning opportunity. Committed to excellence, I don't just meet expectations, I surpass them. Let's create something groundbreaking together!",
	};

	const themeOptions = {
		Teal: {
			hlColor: "#008080",
			hovColor: "#20B2AA",
		},
		Blue: {
			hlColor: "#006994",
			hovColor: "#008bb5",
		},
		Green: {
			hlColor: "#009e66",
			hovColor: "#03b979",
		},
		Purple: {
			hlColor: "#6a5acd", // SlateBlue
			hovColor: "#9370db", // MediumPurple
		},
		Coral: {
			hlColor: "#FF6F61", // A soft coral color for highlights
			hovColor: "#FF8A80", // A lighter coral color for hover states
		},
		Red: {
			hlColor: "#FF4136",
			hovColor: "#FF6255",
		},
		Yellow: {
			hlColor: "#DAA520", // Goldenrod
			hovColor: "#C69520", // A darker shade of Goldenrod for hover
		},
		Orange: {
			hlColor: "#FF851B",
			hovColor: "#FFA040",
		},
	};

	

	const [theme, setTheme] = useState({
		...themeOptions.Purple,
	});

	const changeTheme = (selectedTheme) => {
		setTheme(themeOptions[selectedTheme]);
	};

	useEffect(() => {
		document.documentElement.style.setProperty("--hl-color", theme.hlColor);
		document.documentElement.style.setProperty("--hov-color", theme.hovColor);

		// Update Favicon
		const canvas = document.createElement("canvas");
		canvas.width = 32;
		canvas.height = 32;
		const ctx = canvas.getContext("2d");

		// Font size
		const fontSize = 22;

		// Text "H"
		ctx.fillStyle = theme.hlColor;
		ctx.font = `bold ${fontSize}px Poppins`;
		ctx.textBaseline = "middle";

		// Calculate x-coordinate for "H" to center it within the left half of the canvas
		const textWidthH = ctx.measureText("H").width;
		const xH = (canvas.width / 2 - textWidthH) / 2;

		// Adjust y-coordinate to visually center the text
		const y = canvas.height / 2 + fontSize / 6;

		ctx.fillText("H", xH, y);

		// Text "G"
		ctx.fillStyle = theme.hovColor;
		ctx.font = `bold ${fontSize}px Poppins`;

		// Calculate x-coordinate for "G" to center it within the right half of the canvas
		const textWidthG = ctx.measureText("G").width;
		const xG = canvas.width / 2 + (canvas.width / 2 - textWidthG) / 2;

		ctx.fillText("G", xG, y);

		const favicon = document.getElementById("favicon");
		favicon.href = canvas.toDataURL("image/png");
	}, [theme]);

	return (
		<>
			<ParticlesBg theme={theme} />
			<Header />
			<ThemeToggler
				theme={theme}
				themeOptions={themeOptions}
				handleChangeTheme={changeTheme}
			/>
			<AnimatedRoutes personalDetails={personalDetails} />
			<Analytics />
			<SpeedInsights />
		</>
	);
}

export default App;
