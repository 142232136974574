import React from "react";
import { Dropdown, DropdownToggle, Badge } from "reactstrap";

function ThemeToggler({ theme, themeOptions, handleChangeTheme }) {
	const [dropdownOpen, setdropdownOpen] = React.useState(false);
	const handleClick = () => {
		setdropdownOpen(!dropdownOpen);
	};

	return (
		<div className="fixed-plugin">
			<Dropdown isOpen={dropdownOpen} toggle={handleClick}>
				<DropdownToggle tag="div">
					<i className="fas fa-palette fa-2x" title="Color Palette" />
				</DropdownToggle>
				<ul className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
					<li className="header-title">SELECT THEME</li>
					<li className="adjustments-line">
						<div className="badge-colors text-center">
							{Object.keys(themeOptions).map((option) => (
								<Badge
									key={option}
									className="badge-style" // Add the new CSS class
									style={{
										// Set the dynamic backgroundColor using inline style
										backgroundColor: themeOptions[option].hlColor,
									}}
									onClick={() => {
										handleChangeTheme(option);
										setdropdownOpen(false);
									}}
									onTouchStart={() => {
										handleChangeTheme(option);
										setdropdownOpen(false);
									}}
									title={option}
								>
									{theme.hlColor === themeOptions[option].hlColor && (
										<i className="fa fa-check check-icon" />
									)}
								</Badge>
							))}
						</div>
					</li>
				</ul>
			</Dropdown>
		</div>
	);
}

export default ThemeToggler;
