const SocialIcons = () => {
	const styles = {
		icon: {
			textDecoration: "none",
			fontSize: "22px",
			padding: "10px",
			transition: "0.2s ease-in",
		},
	};

	return (
		<div className="socialIcons" style={styles.socialIcons}>
			<a
				className="icon"
				style={styles.icon}
				href="https://linkedin.com/in/hrithvik-gadhiya"
				target="_blank"
			>
				<i
					className="fa-brands fa-linkedin"
					aria-hidden="true"
					title="Hrithvik Gadhiya's LinkedIn Profile"
				></i>
			</a>
			<a
				className="icon"
				style={styles.icon}
				href="https://github.com/hrithvik123/"
				target="_blank"
			>
				<i
					className="fa-brands fa-github"
					aria-hidden="true"
					title="Hrithvik Gadhiya's GitHub Profile"
				></i>
			</a>
			<a
				className="icon"
				style={styles.icon}
				href="https://stackoverflow.com/users/18411278/hrithvik-gadhiya"
				target="_blank"
			>
				<i class="fa-brands fa-stack-overflow"></i>
			</a>
		</div>
	);
};

export default SocialIcons;
