import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import SocialIcons from "../../components/SocialIcons";
import landingImage from "../../images/nylu-transparent.png";
import { useNavigate } from "react-router-dom";

const Landing = ({ name }) => {
  const styles = {
    landing: {
      height: "calc(100% - 93px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    landingImage: {
      position: "absolute",
      bottom: "0",
      opacity: "0.3",
      mixBlendMode: "lighten",
      height: "80%",
    },

    textContainer: {
      display: "flex",
      flexDirection: "column",
      letterSpacing: "1px",
      textAlign: "center",
      zIndex: "1",
      color: "#fff",
      textShadow: "1px 1px 3px #000",
    },

    name: {
      color: "#fff",
      fontWeight: "700",
      marginTop: "-100px",
      paddingBottom: "28px",
    },
  };
  const navigate = useNavigate();

  return (
    <section className="landing" style={styles.landing}>
      <div className="textContainer" style={styles.textContainer}>
        <h1 className="name" style={styles.name}>
          {name}
        </h1>
        <div className="description">
          <Typewriter
            className="description"
            onInit={(typewriter) => {
              typewriter
                .changeDelay(80)
                // .typeString("I'm a <strong>Software Engineer</strong>")
                // .pauseFor(1500)
                // .deleteChars(17)
                .typeString("I am a <strong>Coder</strong>")
                .pauseFor(500)
                .deleteChars(7)
                .typeString("an <strong>Innovator</strong>")
                .pauseFor(500)
                .deleteChars(12)
                .typeString("a <strong>Software Engineer</strong>")

                .start();
            }}
          />
        </div>
        <div className="call-to-action">
          <button
            className="btn c2a-btn"
            onClick={() => {
              navigate("/portfolio");
            }}
          >
            {"SEE MY WORK"}
          </button>
        </div>
      </div>
      <div className="image-container">
        <motion.img
          className="landingImage"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.8 }}
          transition={{ delay: 0.5, duration: 1, ease: "easeInOut" }}
          style={styles.landingImage}
          src={landingImage}
          alt="Hrithvik Gadhiya"
        />
      </div>
      <SocialIcons />
    </section>
  );
};

export default Landing;
