import React, { useRef, useEffect } from "react";

const ParticlesBg = ({ theme }) => {
	const canvasRef = useRef(null);
	const cloneRef = useRef(null);

	let particles = useRef([]); // Use useRef to persist particles array across renders

	useEffect(() => {
		const canvas = canvasRef.current;
		const clone = cloneRef.current;
		const cloneCtx = clone.getContext("2d");
		const ctx = canvas.getContext("2d");

		let ww = window.innerWidth;
		let wh = window.innerHeight;
		// Adjust partCount based on screen width
		let partCount;
		if (ww <= 480) {
			// Screen width is less than or equal to 480px
			partCount = 30;
		} else if (ww <= 768) {
			// Screen width is between 481px and 768px
			partCount = 50;
		} else {
			// Screen width is greater than 768px
			partCount = 100;
		}

		function hexToRGB(hex) {
			let r = parseInt(hex.slice(1, 3), 16);
			let g = parseInt(hex.slice(3, 5), 16);
			let b = parseInt(hex.slice(5, 7), 16);
			return { r, g, b };
		}

		function Particle() {
			this.setColor = (color) => {
				let { r, g, b } = hexToRGB(color);
				this.color = `rgba(${r},${g},${b},${Math.random()})`;
			};
			this.setColor(theme.hlColor);
			this.x = randomInt(0, ww);
			this.y = randomInt(0, wh);
			this.direction = {
				x: -1 + Math.random() * 2,
				y: -1 + Math.random() * 2,
			};
			this.vx = 0.3 * Math.random();
			this.vy = 0.3 * Math.random();
			this.radius = randomInt(2, 3);

			this.float = () => {
				this.x += this.vx * this.direction.x;
				this.y += this.vy * this.direction.y;
			};

			this.changeDirection = (axis) => {
				this.direction[axis] *= -1;
			};

			this.boundaryCheck = () => {
				if (this.x >= ww) {
					this.x = ww;
					this.changeDirection("x");
				} else if (this.x <= 0) {
					this.x = 0;
					this.changeDirection("x");
				}
				if (this.y >= wh) {
					this.y = wh;
					this.changeDirection("y");
				} else if (this.y <= 0) {
					this.y = 0;
					this.changeDirection("y");
				}
			};

			this.draw = () => {
				ctx.beginPath();
				ctx.fillStyle = this.color;
				ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
				ctx.fill();
			};
		}

		function clearCanvas() {
			cloneCtx.clearRect(0, 0, ww, wh);
			ctx.clearRect(0, 0, ww, wh);
		}

		function createParticles() {
			for (let i = 0; i < partCount; i++) {
				const p = new Particle();
				particles.current.push(p);
			}
		}

		function drawParticles() {
			for (let i = 0; i < particles.current.length; i++) {
				const p = particles.current[i];
				p.draw();
			}
		}

		function updateParticles() {
			for (let i = particles.current.length - 1; i >= 0; i--) {
				const p = particles.current[i];
				p.float();
				p.boundaryCheck();
			}
		}

		createParticles();
		drawParticles();

		function animateParticles() {
			clearCanvas();
			drawParticles();
			updateParticles();
			cloneCtx.drawImage(canvas, 0, 0);
			requestAnimationFrame(animateParticles);
		}

		animateParticles();
		cloneCtx.drawImage(canvas, 0, 0);

		const handleResize = () => {
			ww = window.innerWidth;
			wh = window.innerHeight;
			canvas.width = ww;
			canvas.height = wh;
			clearCanvas();
			particles.current = [];
			// Update partCount based on new screen width
			if (ww <= 480) {
				partCount = 30;
			} else if (ww <= 768) {
				partCount = 50;
			} else {
				partCount = 100;
			}
			createParticles();
			drawParticles();
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		particles.current.forEach((particle) => particle.setColor(theme.hlColor));
	}, [theme]); // Update color on theme change

	return (
		<div>
			<canvas
				className="container"
				id="container"
				role="main"
				ref={canvasRef}
				style={{
					position: "fixed",
					top: 100,
					left: 0,
					pointerEvents: "none",
				}}
				width={window.innerWidth}
				height={window.innerHeight}
			></canvas>
			<canvas
				className="canvas"
				id="blurCanvasBottom"
				ref={cloneRef}
				style={{
					position: "fixed",
					top: 100,
					left: 0,
					pointerEvents: "none",
				}}
				width={window.innerWidth}
				height={window.innerHeight}
			></canvas>
		</div>
	);
};

function randomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export default ParticlesBg;
