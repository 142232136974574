import { webApps, backendProjects, infraProjects } from "./projectsData";
import Project from "../../components/Project";
import PageHeader from "../../components/PageHeader";

const Portfolio = () => {
	const WebAppsList = () =>
		webApps.map((project, i) => (
			<Project
				key={i}
				id={project.id}
				title={project.title}
				technologies={project.technologies}
				image={project.image}
				color={project.bgcolor}
				github={project.github}
				deployed={project.deployed}
				description={project.description}
				projectMedia={project.projectMedia}
			/>
		));

	const BackendProjects = () =>
		backendProjects.map((project, i) => (
			<Project
				key={i}
				id={project.id}
				title={project.title}
				technologies={project.technologies}
				image={project.image}
				color={project.bgcolor}
				github={project.github}
				deployed={project.deployed}
				description={project.description}
				projectMedia={project.projectMedia}
			/>
		));

	const InfraProjects = () =>
		infraProjects.map((project, i) => (
			<Project
				key={i}
				id={project.id}
				title={project.title}
				technologies={project.technologies}
				image={project.image}
				color={project.bgcolor}
				github={project.github}
				deployed={project.deployed}
				description={project.description}
				projectMedia={project.projectMedia}
			/>
		));

	return (
		<section className="portfolio">
			<PageHeader title="Portfolio" description="View my work" />
			<h5 className="pageSubTitle">Full Stack Web Apps</h5>
			<div className="row spacing_bottom">
				<WebAppsList />
			</div>
			<h5 className="pageSubTitle">Backend Projects</h5>
			<div className="row spacing_bottom">
				<BackendProjects />
			</div>
			<h5 className="pageSubTitle">Infrastructure Projects</h5>
			<div className="row spacing_bottom">
				<InfraProjects />
			</div>
		</section>
	);
};

export default Portfolio;
